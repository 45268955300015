.Container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-quiz {
  background-color: #dee1e6;
  width: 700px;
  height: 400px;
  color: rgb(28, 28, 29);
  box-shadow: 0px 5px 8px 6px rgba(128, 120, 120, 0.2),
    4px 5px 7px 6px rgba(128, 120, 120, 0.2);
  border-radius: 10px;
}
.answer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.ans-btn {
  width: 370px;
  height: 35px;
  border: 2px solid #202942;
  margin-top: 20px;
  border-radius: 50px;
  padding: 5px;
  font-size: 15px;
}
.ans-btn:hover {
  background-color: #202942;
  color: #fff;
}
.Question-title {
  text-shadow: 2px 2px 3px rgb(139, 127, 127);
  font-size: 25px;
}
.card-score {
  background-color: #dee1e6;
  width: 700px;
  height: 400px;
  color: rgb(28, 28, 29);
  box-shadow: 0px 5px 8px 6px rgba(128, 120, 120, 0.2),
    4px 5px 7px 6px rgba(128, 120, 120, 0.2);
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.score-section {
  background-color: #202942;
  width: 50%;
  height: 20%;
  box-shadow: 0px 5px 8px 6px rgba(128, 120, 120, 0.2),
    4px 5px 7px 6px rgba(128, 120, 120, 0.2);
  color: #fff;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.score-text {
  font-size: 30px;
}
.card-quiz img {
  width: 200px;
  height: 200px;
}
